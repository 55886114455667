body,
#root {
  margin: 0;
  height: 100vh;
  max-height: 100vh;
  background-color: #282c34;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div:not([class*="ant-statistic-content"]) {
  color: aliceblue !important;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
